import { Renderer2, Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';


@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
})
export class ThankyouComponent implements OnInit {

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document) { }

  ngOnInit() {
    let s = this._renderer2.createElement('script');
        s.type = `text/javascript`;
        s.text = `
          gtag('event', 'conversion', {'send_to': 'AW-727940044/VFhZCI_0g6UBEMz3jdsC'});
        `;

        this._renderer2.appendChild(this._document.body, s);
  }

}