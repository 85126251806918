import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  fighters= [
    { title: 'Obesity Is A Disease', description:'<p>If your Body Mass Index is over 27, it\'s time to take action. Obesity is more than a cosmetic issue. It is a pandemic that is affecting the global population. It is defined as having excess body fat that could cause harmful diseases and damage your health.</p><br><p>Higher the BMI, higher is the risk of associated comorbidities like Type 2 Diabetes, Hypertension, Dyslipidemia (High Cholesterol levels), PCOD, Infertility, Sleep Apnea (Snoring at night with reduced oxygen saturation) and multiple Cancers. Chronic bone pains and wear and tear of joints is a direct result of excess weight. </p>'},
    { title: 'Bariatric Surgery Helps Fight Obesity', description:'<p>Bariatric surgery is the most popular and effective treatment option for patients with morbid obesity or severe obesity with associated medical conditions. It helps them fight obesity and regain the health and lifestyle they deserve.</p><br><p>Bariatric surgery helps in weight loss by altering body\'s food intake capacity, digestion and absorption. This can be achieved through various procedures. The right procedure for a patient can be decided through a doctor\'s consultation, after a review of the patient&rsquo;s medical history and weight loss requirement.</p><p>&nbsp;</p>'},
    { title: 'Is Morbid Obesity Surgery Right For You?', description:'<p>You will qualify for obesity surgery only if:</p><p>&nbsp;</p><ul><li>You are \'Morbidly Obese\' (BMI more than 37.5)</li><li>Your BMI is 32.5-37.5 with severe obesity related medical conditions e.g. diabetes, heart disease, high blood pressure, dyslipidemia, arthritis etc.</li><li>Other methods of weight loss (dietary, medicines) have been unsuccessful.</li><li>You have understood the surgical procedure for weight loss, the risks and after effects involved and are committed to making lifelong behavioral changes pertaining to dietary and physical activities.</li></ul>'},
    { title: 'How Does Obesity Surgery Change Your Life?', description:'<ul><li>Patient must learn to eat food in small amounts, chew it well and slowly.</li><li>Follow-up is necessary in the first year after surgery and then as advised by your surgeon for dietary / nutritional counseling.</li><li>Weight loss starts soon after surgery and continues for 2 years.</li><li>Improvements occur in obesity related medical conditions, with most patients not requiring medications for such conditions.</li><li>There is enhanced quality of life with improved stamina, mood, self-esteem and body image.</li></ul>'}
  ];
  features = [
    { title: 'International Center of Excellence Award by ASMBS & OSSI', icon: 'why_08.png' },
    { title: '24 Hour Patient Care', icon: 'why_07.png' },
    { title: 'Surgeons With International Exposure', icon: 'why_06.png' },
    { title: 'Personalized Patient Care', icon: 'why_05.png' },
    { title: 'Professional Care With Regular Audits', icon: 'why_04.png' },
    { title: 'Day Care Unit', icon: 'why_03.png' },
    { title: 'Luxurious Patient Rooms', icon: 'why_02.png' },
    { title: 'Complimentary Spa Therapy', icon: 'why_01.png' },
  ];
  solutions= [
    { title: 'Bariatric Surgery', description:'Includes a variety of surgical procedures to achieve permanent weight loss for people with obesity.', icon: 'solution_06.png' },
    { title: 'Gastric Band Surgery', description:'Surgical procedure that induces weight loss in obese people by reducing the size of the stomach.', icon: 'solution_04.png' },
    { title: 'Gastric Sleeve Surgery', description:'Helps in weight loss by removing approximately 80% of the stomach, resulting in huge appetite loss.', icon: 'solution_02.png' },
    { title: 'Gastric Bypass Surgery', description:'Helps induce weight loss by reducing the size of the stomach and rerouting its connectivity to small intestine', icon: 'solution_03.png' },
    { title: 'Gastric Balloon Surgery', description:'Helps reduce stomach capacity and appetite by placing a balloon filled with saline inside the stomach', icon: 'solution_05.png' },
    { title: 'Gastric Imbrication Surgery', description:'In this method the stomach is folded and sutured to reduce its size. Similar to gastric sleeve, but reversible.', icon: 'solution_01.png' },
  ];
  // awards= [
  //   { title: 'Livlife Awarded The Best Single Speciality Hospital', description:'Dr Nandakishore Dukkipati receiving the award from the Governor of AP & Telangana at an inaugural function organized by the Indus Foundation on July 23, 2015', image: 'award_01.jpg' },
  //   { title: 'Inducted Into The Medical Council of India', description:'Dr Nandakishore Dukkipati with Andhra Pradesh Chief Minister Shri Chandrababu Naidu after being inducted into the Medical Council of India.', image: 'award_02.jpg' },
  // ]
  constructor() { }

  ngOnInit() {
  }

}
