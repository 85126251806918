import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppointmentService } from '../services/appointment.service';
import { Router  } from '@angular/router';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html'
})

export class FormComponent implements OnInit {

  appointmentForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', Validators.required,),
    phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required)
  });
  submitted = false;
  @Input() bookingForm;
  isBookingForm:boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private appointmentService: AppointmentService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.isBookingForm = this.bookingForm;
    console.log(this.isBookingForm);
  }

  onSubmit() {
    this.submitted = true;
    if (this.appointmentForm.invalid) {
        console.log('invalid', this.appointmentForm)
        return;
    }
    this.appointmentService.bookAppointment(this.appointmentForm.value).subscribe( data => {
      this.submitted = false;
      this.router.navigate(['/thank-you']); 
    })    
  }

}
