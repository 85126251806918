import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { HomeComponent } from './pages/home/home.component';
import { SurgeonComponent } from './pages/surgeon/surgeon.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { ProceduresComponent } from './pages/procedures/procedures.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'thank-you', component: ThankyouComponent },
  { path: 'surgeon', component: SurgeonComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:slug', component: BlogDetailsComponent },
  { path: 'procedures/:slug', component: ProceduresComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '*', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
