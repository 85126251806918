import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surgeon',
  templateUrl: './surgeon.component.html'
})
export class SurgeonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
