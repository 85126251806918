import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html'
})
export class BlogComponent implements OnInit {

  blogs = [
    {
      id: 1,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    },
    {
      id: 2,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    },
    {
      id: 3,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    },
    {
      id: 4,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    },
    {
      id: 5,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    },
    {
      id: 6,
      image_url: 'assets/images/blog-1.jpg',
      title: 'Considering Facial Implants? Know The Facts Before Your Decide',
      slug: 'facts-before-your-decide',
      sort_description: 'Looking good is the best way to help yourself feel good” goes the saying. With the help of the latest technologies if plastic surgery, we can now solve our appearance related problems very easily.',
      content: '',
      created_at: '2019-05-19'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
