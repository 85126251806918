import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private  httpClient : HttpClient,
  ) { }
  bookAppointment(form): Observable<any> {
    console.log(form)
    return this.httpClient.get('https://script.google.com/a/kimshospitals.com/macros/s/AKfycbw_N0yFHhU9aA33iHhjX0FI_OxYJdPE381I1gSkcg/exec', { params: form })
  }
}
