import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
})
export class TestimonialComponent implements OnInit {
   public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    loop: true,
    navigation: {
      nextEl: '.custom-next',
      prevEl: '.custom-prev',
      disabledClass: 'disabledClass'
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
