import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html'
})
export class ProceduresComponent implements OnInit {

  service = {}
  videoUrl = null
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService : DataService,
    private sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe(routeParams => {
      let ser = this.dataService.getServicesBySlug(routeParams.slug);
      if (ser !== undefined){
        this.service = ser;
        this.updateVideoUrl(ser.youtube)
        this.service
      } else {
        this.router.navigate(['/404'])
      }
    });
   }

  ngOnInit() {
    
  }

  updateVideoUrl(id: string) {
    let dangerousVideoUrl = 'https://www.youtube.com/embed/' + id;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dangerousVideoUrl);
  }
}
