import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'kims-livlife';

  toBookForm() {
    document.getElementById("stickyBottomFormAug").scrollIntoView();
  }
}
