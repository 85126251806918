import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  services = [
    {
      title : 'Bariatric Surgery',
      slug: 'bariatric-surgery',
      image_url: '/assets/images/bariatric_surgery.jpg',
      content: `
      <h2><span style="font-weight: 400;">Weight Loss Simplified</span></h2>
      <p><span style="font-weight: 400;">Excess weight affects life and prevents us from enjoying day to day activities. It makes each day a weighty problem.Many conditions like hormonal imbalance, hereditary conditions, poor diet choices and lack of physical activity over a long period are responsible for weight gain and obesity. Several medical conditions are associated with being overweight like diabetes, hypertension, heart related ailments, sleep apnea, joint issues etc. Significant improvement or resolution of these conditions can be obtained by losing excess weight.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Not all weight gain and excess weight can be conquered with exercise and diet therapy. Bariatric surgery may be the next step for people who remain severely obese after exhausting all non-surgical approaches or for people who have an obesity-related illness.</span></p>
      <h2><span style="font-weight: 400;">What is Bariatric Surgery?</span></h2>
      <p><span style="font-weight: 400;">Bariatric surgery (weight loss surgery) includes a variety of procedures performed on people who have obesity. Weight loss is achieved by reducing the size of the stomach with a gastric band or through removal of a portion of the stomach (sleeve gastrectomy or biliopancreatic diversion with duodenal switch) or by resecting and re-routing the small intestines to a small stomach pouch (gastric bypass surgery).</span></p>
      <h2><span style="font-weight: 400;">How Can Bariatric Surgery Be Called Successful?</span></h2>
      <p><span style="font-weight: 400;">The success of your bariatric surgery requires commitment and several significant lifestyle changes.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">You&rsquo;ll need to watch portion sizes, eat healthful foods, and take up an exercise regimen. So apart from weight loss, bariatric surgery has many other health benefits.</span></p>
      <h2><span style="font-weight: 400;">What Health Benefits Does Bariatric Surgery Offer?</span></h2>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Working alongside your bariatric care team, you may experience the following health benefits following your bariatric surgery:</span></p>
      <p>&nbsp;</p>
      <ul>
      <li style="font-weight: 400;"><strong>Treatment of Diabetes:</strong><span style="font-weight: 400;"> Research suggests that people with type 2 diabetes who undergo bariatric surgery may experience long-term remission of the disease. If you have type 2 diabetes and maintain weight loss following bariatric surgery, you could modify or eliminate your diabetes medication.</span></li>
      <li style="font-weight: 400;"><strong>Healthier Heart:</strong><span style="font-weight: 400;"> Losing weight can help you reduce your risk of cardiovascular disease and stroke by significantly lowering blood pressure and cholesterol levels.</span></li>
      <li style="font-weight: 400;"><strong>Lowering Joint and Low Back Pain: </strong><span style="font-weight: 400;">When you shed weight, you take the pressure off of the weight-bearing joints in your hips, knees and lower back, potentially allowing you to cut down or eliminate the need for pain-relieving medication.</span></li>
      <li style="font-weight: 400;"><strong>Better Sleep:</strong><span style="font-weight: 400;"> If you suffer from obesity-related sleep apnoea, losing weight through bariatric surgery could help improve the condition and eliminate your need for treatment, including the CPAP machine.</span></li>
      <li style="font-weight: 400;"><strong>Improved Mood:</strong><span style="font-weight: 400;"> People who lose weight may experience improvements in depression, body image, and social isolation&mdash; all measures of mental and emotional health.</span></li>
      </ul>
      <h2><span style="font-weight: 400;">How Does Bariatric Surgery Indirectly Help Health Improvement?</span></h2>
      <p><span style="font-weight: 400;">Bariatric Surgery helps weight loss which in turn helps improve following medical conditions:</span></p>
      <p>&nbsp;</p>
      <ul>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Metabolic Syndrome</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Infertility</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Gallbladder Disease</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Pregnancy Complications</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Fatty Liver Disease</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Urinary Incontinence</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Non-Alcoholic Steatohepatitis</span></li>
      </ul>
      <h2><span style="font-weight: 400;">Candidates for Bariatric Surgery</span></h2>
      <p><span style="font-weight: 400;">Bariatric surgery is a major decision and should be considered as an informed choice. It is a serious and lifelong commitment to lifestyle changes.Individuals with a BMI (Body Mass Index) of 30 or more, with illness related to excess weight and people with BMI more than 37 are candidates for weight-loss surgery. KIMS LivLife Centre has supported patients between the ages of 18 and 65, with some exceptions on individual requirement basis.</span></p>
      <h2><span style="font-weight: 400;">Why KIMS LivLife Centre?</span></h2>
      <p><span style="font-weight: 400;">KIMS LivLife Centre is the leading Bariatric Surgery Hospital in Hyderabad with a comprehensive program providing patients with personalised attention as they work towards achieving their weight loss goals. It is this dedication and an eye for quality and excellence that has won us the recognition of &lsquo;International Centre of Excellence For Bariatric Surgery. We have the distinction of being the only centre to achieve the status of &lsquo;International Centre of Excellence&rsquo; (ICE, OSSI COE status) by SRC (Surgical Review Corporation) in the very first year of operations.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Our approach weight loss is based on evidence based methods, which include conservative and interventional methods that provide long lasting results. We offer five bariatric surgical options that require shorter hospital stay and faster return to work and activity.</span></p>
      <h2><span style="font-weight: 400;">KIMS LivLife Centre: Changing the way you live life</span></h2>
      <p><span style="font-weight: 400;">Bariatric surgery is just one piece of the weight loss puzzle. Pre-operative counselling, surveillance during the weight loss period and a lifetime of ongoing support and education from the Bariatric team are essential to make the most of your life as you continue to manage your weight loss. We provide a 360 approach to care. Our expert healthcare team is led by American Board of Surgery certified surgeon specializing in Bariatric Surgery, seasoned surgical consultants, bariatric physician, intensive care specialists, registered dieticians, Physical therapists, sleep medicine experts, psychologists, a bariatric coordinator and a variety of specialists who appropriately address the specific needs of bariatric patients.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">To learn more about weight loss surgery and other resources to help you explore your options, meet our experienced counsellors</span></p>
      <p>&nbsp;</p>`
    },
    {
      title : 'Gastric Band Surgery',
      slug: 'gastric-band-surgery',
      image_url: '/assets/images/gastric-band-surgery.jpg',
      content: `
      <p>The laparoscopic adjustable gastric banding surgery procedure involves placing an adjustable, inflatable silicone band around the upper part of the stomach. The technique makes the capacity of stomach smaller, thereby restricting the amount of food that can be eaten. Patients generally can return to work after about one week. In addition, gastric band can be adjusted (without further surgery) later to enhance weight loss. This is amongst the least invasive weight loss surgery options available. Overnight stay at the hospital might be required. 
      </p>
      <p>&nbsp;</p>`,
      youtube: '4MSPu_F6vtQ'
    },
    {
      title : 'Gastric Sleeve Surgery',
      slug: 'gastric-sleeve-surgery',
      image_url: '/assets/images/Gastric-sleeve-surgery.jpg',
      content: `
      <h2><span style="font-weight: 400;">What is Gastric Sleeve Surgery or Sleeve Gastrectomy?</span></h2>
      <p><span style="font-weight: 400;">Sleeve gastrectomy is a restrictive surgical procedure. It greatly reduces the size of your stomach and limits the amount of food that can be eaten at one time. It does not cause decreased absorption of nutrients or bypass your intestines. After eating a small amount of food, you will feel full very quickly and continue to feel full for several hours.</span></p>
      <p>&nbsp;</p>
      <p><span style="font-weight: 400;">Sleeve gastrectomy may also cause a decrease in appetite. In addition to reducing the size of the stomach, sleeve gastrectomy may reduce the amount of "hunger hormone" produced by the stomach which may contribute to weight loss after this procedure.</span></p>
      <h2><span style="font-weight: 400;">How Gastric Sleeve Surgery Is Done?</span></h2>
      <p><span style="font-weight: 400;">A long, narrow tube created by reducing the size of stomach vertically. This leaves approximately 15% of the stomach to act as a narrow pouch or tube which helps in early satiety with small amount of food, while the remainder of the stomach is separated and removed. The removed portion of the stomach is responsible for secreting the hormone &lsquo;Ghrelin&rsquo; which stimulates hunger. Because Ghrelin secretion reduces following the procedure, appetite and feelings of hunger reduce significantly. Requires one to two night stay at hospital.</span></p>
      <h2><span style="font-weight: 400;">Busting The Myths Associated with Sleeve Gastrectomy?</span></h2>
      <p><span style="font-weight: 400;">Some of the myths associated with sleeve gastrectomy are:</span></p>
      <p>&nbsp;</p>
      <p><strong>Myth:</strong><span style="font-weight: 400;"> You can eat what you want after gastric sleeve surgery.&nbsp;</span></p>
      <p><strong>Fact: </strong><span style="font-weight: 400;">It&rsquo;s common for patients to assume that once they undergo gastric sleeve surgery, they will be able to eat whatever they want and still lose weight. Although you will experience restrictions regarding how much food you can eat at one time, eating healthy is an important part of your weight loss plan. Typically, doctors recommend that patients eat primarily proteins and vegetables and only small amounts of carbohydrates. Developing healthy eating habits will be key to your long-term success.</span></p>
      <p>&nbsp;</p>
      <p><strong>Myth: </strong><span style="font-weight: 400;">Your surgery will ensure that you have weight loss results.&nbsp;</span></p>
      <p><strong>Fact:</strong><span style="font-weight: 400;"> It is true that gastric sleeve surgery can play a critical role in your ability to reach a healthy weight but as a patient, you will have some responsibilities as well. You will need to follow your doctor&rsquo;s instructions closely, attend any necessary follow-up visits, and be prepared to make some lifestyle changes following your gastric surgery procedure.</span></p>
      <p>&nbsp;</p>
      <p><strong>Myth: </strong><span style="font-weight: 400;">You must exercise to lose weight after gastric sleeve surgery.&nbsp;</span></p>
      <p><strong>Fact: </strong><span style="font-weight: 400;">By following a healthy eating plan and adhering to the dietary guidelines set forth by your doctor, you can expect to lose weight after your surgery. Due to the restricted number of calories that you&rsquo;ll be consuming, vigorous exercise is not required. However, being active is incredibly important for your overall wellness, and exercise will promote better health and can help you develop better lifestyle habits.</span></p>
      <p>&nbsp;</p>
      <p><strong>Myth: </strong><span style="font-weight: 400;">Sleeve surgery is reversible, like other weight loss surgeries.&nbsp;</span></p>
      <p><strong>Fact: </strong><span style="font-weight: 400;">Sleeve gastrectomy and duodenal switch entail actual removal of part of the stomach without reattaching it lower on the intestine. This type of surgery is not reversible. However other weight-loss surgeries are reversible. Gastric banding is usually reversible. That&rsquo;s because the stomach and intestines are not cut or stapled with this surgery. Gastric bypass may be reversible, but this is a very involved surgery. It&rsquo;s more difficult to put everything back where it was, and there is a risk that the revision won&rsquo;t restore normal function.</span></p>
      <h2><span style="font-weight: 400;">Why KIMS LivLife?</span></h2>
      <p><span style="font-weight: 400;">KIMS LivLife, one of the best bariatric surgery hospitals in Hyderabad, is recognized locally, state-wide, nationally and even internationally as a top choice for those who are ready for weight-loss surgery. Our top bariatric surgeon in Hyderabad, Dr. Nandakishore is the most experienced in the region and provides all kinds of weight-loss surgeries including sleeve gastrectomy.&nbsp;</span></p>
      <p>&nbsp;</p>`,
      youtube: 'LY-_RJ1i7y0'
    },
    {
      title : 'Gastric Bypass Surgery',
      slug: 'gastric-bypass-surgery',
      image_url: '/assets/images/gastric-bypass-surgery.jpg',
      content: `
      <h2><span style="font-weight: 400;">What Is Gastric Bypass Surgery?</span></h2>
      <p><span style="font-weight: 400;">Gastric bypass surgery refers to a surgical procedure in which the stomach is divided into a small upper pouch and a much larger lower "remnant" pouch and then the small intestine is rearranged to connect to both. It is generally done laparoscopically, which means surgeons use minimally invasive surgical techniques to reduce the size of the stomach and permit food to bypass part of the small intestine. This reduces the amount of food patients can eat at one time, limits food absorption and is associated with favourable hormonal changes. The procedure results in weight loss and, ultimately, remission or improvement of the patient&rsquo;s diabetes.</span></p>
      <h2><span style="font-weight: 400;">Is Laparoscopic Gastric Bypass Surgery Risky?</span></h2>
      <p><span style="font-weight: 400;">Laparoscopic gastric bypass surgery for patients with type 2 diabetes was once considered a high- risk procedure. But a new study shows that complication and death rates for the procedure is comparable to some of the safest and most commonly performed surgeries. Researchers of a new study found that the surgery is as safe as other surgeries done laparoscopically such as gallbladder surgery, appendectomy, partial colon resections, hysterectomy, heart surgery and total knee replacement.</span></p>
      <h1>&nbsp;</h1>
      <p><span style="font-weight: 400;">Research also showed that gastric bypass patients had significantly better short-term outcomes in all examined variables compared to laparoscopic colon resections.</span></p>
      <h2><span style="font-weight: 400;">Do The Benefits Outweigh The Risks?</span></h2>
      <p><span style="font-weight: 400;">Some of the benefits are:</span></p>
      <h1>&nbsp;</h1>
      <ul>
      <li style="font-weight: 400;"><span style="font-weight: 400;">For patients with type 2 diabetes, gastric bypass procedures can have a nearly immediate effect. Sometimes patients walk out of the hospital without medications.</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">There&rsquo;s significant weight loss, diabetes improvement or remission, and a relatively low complication and mortality rate.</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">In addition, earlier intervention with metabolic surgery may eliminate the need for some later higher-risk procedures to treat cardiovascular complications of diabetes.</span></li>
      <li style="font-weight: 400;"><span style="font-weight: 400;">Metabolic/bariatric surgery procedures, such as gastric bypass, are the most effective and longest-lasting treatment for morbid obesity and comorbidities including diabetes.</span></li>
      </ul>
      <h1>&nbsp;</h1>
      <h2><span style="font-weight: 400;">Why KIMS?</span></h2>
      <p><span style="font-weight: 400;">At KIMS, one of the best gastric bypass surgery hospitals, we offer a wide range of weight loss surgery procedures to help you attain a healthier weight, including weight loss surgery for type 1 diabetes. Our bariatric surgery specialist Dr. Nandakishore Dukkipati is an expert in all kinds of obesity surgery as well as fat reduction surgery. We have a longstanding reputation for excellence and innovation in bariatric surgery.</span></p>
      <h1>&nbsp;</h1>
      <p><span style="font-weight: 400;">Get a Appointment with Dr. Nandakishore by filling out the form below.</span></p>
      <p>&nbsp;</p>`,
      youtube: '-K9ebnalmss'
    },
    {
      title : 'Gastric Balloon Surgery',
      slug: 'gastric-balloon-surgery',
      image_url: '/assets/images/gastric-balloon-surgery.jpg',
      content: `
      <p>This procedure is performed with an endoscope and takes approximately 15 minutes. A balloon filled with saline is placed inside the stomach cavity. This makes the stomach feel full with small quantity of food, helps in weight loss and also prevents piling on additional weight. Balloon can be removed in just a few minutes with an endoscope after 6 months.
      </p>
      <h1>&nbsp;</h1>
      <p>A daycare procedure.</p>
      <p>&nbsp;</p>`,
      youtube: '6Rlk6xQ35sY'
    },
    {
      title : 'Gastric Imbrication Surgery',
      slug: 'gastric-imbrication-surgery',
      image_url: '/assets/images/gastric-imbrication-surgery.jpg',
      content: `<p>The stomach is folded and sutured in this method to reduce the size. The surgery is reversible and offers the benefits of Gastric Sleeve while being reversible, similar to Gastric Band. </p>
      <h1>&nbsp;</h1>
      <p>Short hospital stay</p>
      <p>&nbsp;</p>`,
      youtube: 'AKQ6snbmXy4'
    }
  ] 

  constructor() { }
  getServices(){
    return this.services
  };
  getServicesBySlug(slug){
    return this.services.find(e => e.slug == slug)
  }
}

