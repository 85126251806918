import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { FormComponent } from './form/form.component'
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { HomeComponent } from './pages/home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { FooterformComponent } from './footerform/footerform.component';
import { HeaderComponent } from './components/header/header.component';
import { SurgeonComponent } from './pages/surgeon/surgeon.component';
import { AllServicesComponent } from './components/all-services/all-services.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { ProceduresComponent } from './pages/procedures/procedures.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1
};

@NgModule({
  declarations: [
    AppComponent,
    TestimonialComponent,
    FormComponent,
    ThankyouComponent,
    HomeComponent,
    FooterformComponent,
    HeaderComponent,
    SurgeonComponent,
    AllServicesComponent,
    BlogComponent,
    BlogDetailsComponent,
    ProceduresComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
